// Spanish Translations
export default {
  Flip: "Capirotazo",
  Close: "Cerrar",
  Share: "Compartir",
  "Artwork by ": "Obra de arte por ",
  "Learn About the Artist": "Aprende sobre la artista",
  "See more of their work at: ": "Ver más de su trabajo en: ",
  "Share Instagram Story": "Compartir historia de Instagram",
  "Download and Share": "Descargar y compartir",
  Scroll: "Desplazamiento",
  "Equality Fund homepage": "Página de inicio del Fondo Igualdad",
  About: "Acerca",
};
