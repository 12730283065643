import React from "react";
import { Image } from "@atoms";

const DeepDiveSlide = ({ color, image }) => {
  return (
    <div className="pointer-events-none relative h-[calc(100vh-4.2rem)] w-full min-w-[1055px] shrink-0 overflow-hidden ">
      <Image image={image} preserveAspectRatio className="h-full" />
    </div>
  );
};

DeepDiveSlide.defaultProps = {};

export default DeepDiveSlide;
