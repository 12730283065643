import React, { useEffect, useState } from "react";
import { Input, Text, Fieldset } from "@atoms";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import FormContainer from "./FormContainer";

const FooterNewsletter = ({ heading }) => {
  const lang = useLang();
  const [form, setForm] = useState({ lang: "en" });

  useEffect(() => {
    setForm(f => ({ ...f, lang }));
  }, [lang]);

  const submitForm = async e => {
    try {
      // Make request to submit form with updated formData

      const data = await fetch("/api/mailchimp", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(form),
      });

      return data.json();
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Text variant="body" className="mb-4 font-bold uppercase text-white">
        {heading}
      </Text>
      <FormContainer onSubmit={submitForm} formState={[form, setForm]}>
        <Fieldset className="space-y-4">
          <Input
            id="email"
            name="email"
            required
            label={`${t("Email Address", lang)}:`}
          />
          <Input
            id="country"
            name="country"
            required
            label={`${t("Country", lang)}:`}
          />
        </Fieldset>
      </FormContainer>
    </>
  );
};

FooterNewsletter.defaultProps = {};

export default FooterNewsletter;
