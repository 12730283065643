import React from "react";

const Divider = () => {
  return <div className="my-6 border-2 border-black" />;
};

Divider.defaultProps = {};

Divider.propTypes = {};

export default Divider;
