import React from "react";
import classNames from "classnames";
import { Image, Text } from "@atoms";

const PortalImage = ({
  image,
  caption,
  className: _className,
  captionColor,
  captionClasses,
  blackBackground,
  grayscale,
  bottom,
  long,
}) => {
  return (
    <div className={classNames(_className)}>
      <Image
        resolution={5}
        image={image}
        caption={caption}
        ixParams={grayscale ? { sat: -100 } : null}
        className={classNames(
          "relative aspect-portal overflow-hidden rounded-t-full",
          {
            "texture-pattern bg-black": blackBackground,
            "bg-gradient-to-t from-viridian to-viridian-light":
              !blackBackground,
            "aspect-long-portal": long,
            "aspect-portal": !long,
          }
        )}
      />
      {(caption || image?.caption) && (
        <div className="mt-4" aria-hidden="true">
          <Text
            variant="caption"
            className={classNames(captionClasses, {
              "text-white": captionColor === "white",
              "text-black": captionColor === "black",
            })}
          >
            {caption}
          </Text>
        </div>
      )}
    </div>
  );
};

PortalImage.defaultProps = {
  captionColor: "white",
};

export default PortalImage;
