import React, { useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useDynamicSVGImport } from "@utils";

const Icon = React.memo(
  ({ name, className: _className, encode, fitHeight }) => {
    const icon = useRef();
    const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
    return (
      <span
        ref={icon}
        className={classNames(
          "block fill-current",
          { icon: !fitHeight, "icon--fit-height": fitHeight },
          _className
        )}
      >
        <span className="sr-only">{name}</span>
        {!encode && !loading && SvgIcon && <SvgIcon />}
        {encode && !loading && SvgIcon && (
          <img alt={name} className="block h-full w-full" src={SvgIcon} />
        )}
      </span>
    );
  }
);

Icon.defaultProps = {
  className: "w-16 h-16",
};

Icon.propTypes = {
  name: propTypes.string.isRequired,
  className: propTypes.string,
};

export default Icon;
