const reducer = (state, action) => {
  switch (action.type) {
    case "toggleLang":
      return {
        ...state,
        lang: state.lang === "en" ? "fr" : "en",
      };
    case "setLang":
      return {
        ...state,
        lang: action.lang,
      };

    default:
      return state;
  }
};

const initialState = {
  lang: null,
};

export default {
  reducer,
  initialState,
};
