import React from "react";
import { IntentionModalContent } from "@molecules";
import { useAppState } from "@state";
import useIntentionsData from "@staticQueries/IntentionGlobalQuery";
import t from "@utils/t";

const IntentionAbout = ({ lang }) => {
  const [, dispatch] = useAppState();
  const { buttonText, about } = useIntentionsData()[lang || "en"];

  const openModal = () => {
    dispatch({
      type: "openModal",
      title: buttonText,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => <IntentionModalContent {...about} />),
      background: "bg-black/75",
    });
  };

  return (
    <button
      type="button"
      onClick={() => openModal()}
      className="transition duration-300 hover:opacity-70"
      aria-label={buttonText}
    >
      <span className="hidden text-xs font-bold sm:block" aria-hidden="true">
        {buttonText}
      </span>
      <span className="block text-xs font-bold sm:hidden" aria-hidden="true">
        {t("About", lang)}
      </span>
    </button>
  );
};

IntentionAbout.defaultProps = {};

export default IntentionAbout;
