import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse,
  icon,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={classNames("inline-flex items-center", _className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={classNames(
            "icon flex-shink-0 mr-1 h-4 w-4 rotate-180 transform",
            {}
          )}
        >
          <Icon name={icon || "chevron"} />
        </span>
      )}
      {/* label */}
      <span className="border-b-4 border-transparent transition-[border-color] hover:border-current focus:border-current active:border-current">
        {children}
      </span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span className="icon flex-shink-0 ml-1 h-4 w-4">
          <Icon name={icon || "chevron"} />
        </span>
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  icon: false,
};

export default ButtonAlt;
