const reducer = (state, action) => {
  switch (action.type) {
    case "enableSideScroll":
      return {
        ...state,
        active: true,
        id: action.id,
        content: action.content,
      };
    case "disableSideScroll":
      return { ...state, active: false };
    case "clearSideScrollContent":
      return { ...state, active: false, id: null, content: null };
    default:
      return state;
  }
};

const initialState = {
  active: false,
  id: null,
  content: null,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
