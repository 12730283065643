import React, { useMemo } from "react";
import { m, useTransform } from "framer-motion";
import classNames from "classnames";

const Thumbprint = ({ progress, align }) => {
  const minMax = (val, min, max) => {
    return Math.min(Math.max(val, min), max);
  };

  const amountOfPrints = useMemo(() => Array(70).fill(0), []);

  const scale = useTransform(progress, [0.3, 1], [1, 40], {
    clamp: false,
    ease: x => {
      return (
        // eslint-disable-next-line no-unsafe-optional-chaining
        Math.ceil(minMax(x, 0.00001, 1) * amountOfPrints?.length) *
        // eslint-disable-next-line no-unsafe-optional-chaining
        (1 / amountOfPrints?.length)
      );
    },
  });

  return (
    <m.svg
      className={classNames(
        "relative z-0 shrink-0 overflow-visible fill-transparent stroke-grey opacity-60",
        {
          "md:ml-[6%]": align === "left",
          "mx-auto": align === "center",
        }
      )}
      viewBox="0 0 79 103"
      width="79"
      height="103"
      xmlns="http://www.w3.org/2000/svg"
      style={{ clipPath: "url(#thumbMask)" }}
    >
      <m.clipPath id="thumbMask" style={{ scale }} className="!origin-center">
        <path
          d="M76.6249 100.971H2.37256V39.4611C2.37256 18.7931 19.0678 2.04688 39.6418 2.04688C60.2106 2.04688 76.6249 18.6602 76.6249 39.174V100.971Z"
          strokeMiterlimit="10"
          x="0"
          y="0"
          className="w-full"
        />
      </m.clipPath>
      {amountOfPrints?.map((t, i, a) => {
        const scaleX = Math.max(1 + i / 1.1, 1);
        const transform = `scale(${scaleX}, ${scaleX})`;
        return (
          <path
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            d="M76.6249 100.971H2.37256V39.4611C2.37256 18.7931 19.0678 2.04688 39.6418 2.04688C60.2106 2.04688 76.6249 18.6602 76.6249 39.174V100.971Z"
            strokeWidth="4"
            strokeMiterlimit="10"
            vectorEffect="non-scaling-stroke"
            x="0"
            y="0"
            style={{ transform }}
            className="origin-center"
          />
        );
      })}
    </m.svg>
  );
};

Thumbprint.defaultProps = {
  align: "left",
};

export default Thumbprint;
