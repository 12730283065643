import React from "react";
import classNames from "classnames";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
  dewidow,
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-neutral text-4xl md:text-6xl lg:text-h1 leading-tighter uppercase font-bold";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-magilio text-3xl sm:text-4xl md:text-5xl lg:text-h2 leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes =
        "font-sans font-bold uppercase font-bold text-2.5xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes =
        "font-neutral font-bold text-1.5xl lg:text-h4 uppercase leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes =
        "font-neutral text-lg md:text-1.5xl lg:text-h5 leading-tighter uppercase font-bold";
      break;
    case "h6":
      Tag = "h6";
      classes =
        "font-neutral text-base md:text-lg lg:text-h6 leading-tight uppercase font-bold";
      break;
    case "xl":
      classes = "block text-lg md:text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-sm md:text-h6 font-bold leading-none font-neutral uppercase tracking-0";
      break;
    case "navItem":
      classes =
        "block text-3xl font-bold leading-none font-neutral uppercase tracking-1";
      break;
    case "button":
      classes =
        "block text-base font-bold leading-none font-sans uppercase tracking-0";
      break;
    case "buttonSmall":
      classes =
        "block text-xs font-bold leading-none font-sans uppercase tracking-2";
      break;
    case "caption":
      classes = "block text-xs leading-tight font-sans italic";
      break;
    case "captionHeading":
      classes = "block text-sm leading-tight font-sans font-bold";
      break;
    case "metric":
      Tag = "span";
      classes = "font-magilio text-6xl md:text-7xl lg:text-metric leading-none";
      break;
    case "fr-metric":
      Tag = "span";
      classes = "font-magilio text-6xl md:text-7xl lg:text-8xl leading-none";
      break;
    case "metricLabel":
      Tag = "span";
      classes = "font-sans font-bold text-2xl sm:text-3xl leading-tighter";
      break;
    case "footerLinkHeader":
      Tag = "h5";
      classes = "font-magilio font-bold text-2xl leading-tighter";
      break;
    case "sidewaysLabel":
      Tag = "p";
      classes =
        "block text-orientation-l-to-r text-[0.715rem] uppercase font-bold tracking-2";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={classNames(classes, _className, {
            quotes,
            // "prose prose-sm md:prose-base": richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={classNames(classes, _className, {
          quotes,
          // "prose prose-sm md:prose-base": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
