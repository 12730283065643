import React from "react";
import { m } from "framer-motion";

const AnimateIn = ({
  preset,
  threshold,
  children,
  callback,
  triggerAnimation,
  delay,
  animateInOut,
  className,
}) => {
  const hasTrigger = typeof triggerAnimation !== "undefined";

  const presets = {
    fadeIn: {
      initialStyle: {
        opacity: 0,
      },
      animation: {
        opacity: 1,
        transition: {
          duration: 0.8,
          delay,
        },
      },
    },
    scaleIn: {
      initialStyle: {
        opacity: 0,
        scale: 0.96,
      },
      animation: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.8,
          delay,
        },
      },
    },
    fadeUp: {
      initialStyle: {
        opacity: 0,
        y: "5rem",
      },
      animation: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.8,
          delay,
        },
      },
    },
    fadeUpFast: {
      initialStyle: {
        opacity: 0,
        y: "2.5rem",
      },
      animation: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay,
        },
      },
    },
    fadeUpSlow: {
      initialStyle: {
        opacity: 0,
        y: "10rem",
      },
      animation: {
        opacity: 1,
        y: 0,
        transition: {
          delay,
        },
      },
      duration: 0.8,
    },
  };

  const getAnimation = condition => {
    if (condition) {
      return presets[preset].animation;
    }
    if (!condition && animateInOut) {
      return presets[preset].initialStyle;
    }
    return false;
  };

  return (
    <m.div
      className={className}
      initial={presets[preset].initialStyle || false}
      whileInView={!hasTrigger ? presets[preset].animation : false}
      animate={hasTrigger ? getAnimation(triggerAnimation) : false}
      viewport={{
        once: !animateInOut,
        amount: threshold,
      }}
      onAnimationEnd={() => {
        if (callback) {
          callback();
        }
      }}
      variants={presets}
    >
      {children}
    </m.div>
  );
};

AnimateIn.defaultProps = {
  preset: "fadeUp",
  threshold: 0.1,
  callback: null,
  triggerAnimation: undefined,
  delay: undefined,
  animateInOut: false,
  className: "",
};

export default AnimateIn;
