import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";

const Player = loadable(() => import("./Video/Player"));

const Video = props => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true));
  if (loaded) return <Player {...props} />;
  return <div className="aspect-video" />;
};

Video.defaultProps = {
  loop: true,
  autoplay: true,
  showControls: false,
  preserveAspectRatio: true,
};

export default Video;
