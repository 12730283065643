import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Dropdown, Text } from "@atoms";
import { AnimatePresence, m } from "framer-motion";

const FilteredList = ({ items, className: _className }) => {
  const types = [
    { uid: "", title: "All" },
    { uid: "a1", title: "Type 1" },
    { uid: "a2", title: "Type 2" },
    { uid: "a3", title: "Type 3" },
  ];
  const topics = [
    { uid: "", title: "All" },
    { uid: "b1", title: "Topic 1" },
    { uid: "b2", title: "Topic 2" },
    { uid: "b3", title: "Topic 3" },
  ];

  // TODO: move to hook?
  const [type, setType] = useState(0);
  const [topic, setTopic] = useState(0);
  const urlFilter =
    typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter(
        item =>
          (type === 0 || item.types.includes(types[type].uid)) &&
          (topic === 0 || item.topics.includes(topics[topic].uid))
      )
    );
  }, [type, topic]);

  // set topic based on the url parameter
  useEffect(() => {
    if (urlFilter) {
      setTopic(topics.findIndex(t => t.uid === urlFilter));
    }
  }, [urlFilter]);

  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <div className={classNames(_className)}>
      {/* filters */}
      <div className="flex flex-wrap gap-4">
        <div className="flex w-full items-center space-x-2">
          <Text>Type:</Text>
          <Dropdown model={[type, setType]} options={types} />
        </div>
        <div className="flex w-full items-center space-x-2">
          <Text>Topic:</Text>
          <Dropdown model={[topic, setTopic]} options={topics} />
        </div>
      </div>

      {/* filtered items */}
      <div className="mt-8 flex flex-col items-center space-y-12">
        <AnimatePresence exitBeforeEnter>
          <div className="grid w-full grid-cols-2 gap-6">
            {!!filteredItems?.length &&
              filteredItems.map(item => {
                return (
                  <m.div
                    key={item.uid}
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={variants}
                    className="flex h-12 w-full items-center justify-center bg-red text-xs"
                  >
                    {item.text}
                  </m.div>
                );
              })}

            {!filteredItems?.length && (
              <m.div
                initial="exit"
                animate="enter"
                exit="exit"
                variants={variants}
                className="col-span-2 flex flex-col items-center justify-center"
              >
                <Text variant="xl">
                  There are no items that match your selected filters.
                </Text>
              </m.div>
            )}
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};

FilteredList.defaultProps = {};

export default FilteredList;
