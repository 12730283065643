import React, { useState } from "react";
import { Text, Container, Image, ButtonAlt } from "@atoms";
import { useAppState } from "@state";
import classNames from "classnames";
import { m, AnimatePresence } from "framer-motion";

const MobileHeader = ({
  nav,
  subnav: footerNav,
  backgroundState,
  imageState,
}) => {
  const [{ layout }] = useAppState();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [background, setBackground] = backgroundState || useState("black");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleImage, setVisibleImage] = imageState || useState(null);
  const darkBackground = background === "black" || background === "viridian";

  // Mobile nav triggers
  const { hideNav } = layout;

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
      >
        <AnimatePresence>
          {!hideNav && (
            <m.div
              className={classNames(
                "absolute top-0 left-0 right-0 z-0 h-full min-h-screen w-full overflow-y-auto pt-20 pb-2 transition-colors duration-300",
                {
                  "pointer-events-none": hideNav,
                  "bg-black": background === "black",
                  "bg-viridian": background === "viridian",
                  "bg-offwhite": background === "offwhite",
                  "bg-light-green": background === "light-green",
                  "bg-coral": background === "coral",
                }
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Container className="flex flex-col justify-between">
                {/* Main Nav */}
                <div className="flex items-center justify-between pt-4 pb-4 xl:pt-12">
                  <ul className="space-y-2 px-2">
                    {nav?.map((link, i) => {
                      const { text, url } = link;

                      return (
                        <li
                          key={`header-link--${i + 1}`}
                          className={classNames("transition duration-300", {
                            "text-white": darkBackground,
                            "text-black": !darkBackground,
                          })}
                        >
                          <ButtonAlt
                            to={url}
                            onMouseEnter={() => {
                              setVisibleImage(i);
                              setBackground(link?.color);
                            }}
                            onMouseLeave={() => {
                              setVisibleImage(null);
                              setBackground("black");
                            }}
                          >
                            <Text className="inline-block" variant="navItem">
                              {text}
                            </Text>
                          </ButtonAlt>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="hidden w-48 md:mr-[10%] md:block md:w-60 xl:w-[333px]">
                    <div className="relative aspect-portal w-full overflow-hidden rounded-t-full bg-gradient-to-t from-viridian to-light-green">
                      <AnimatePresence>
                        {visibleImage !== null && (
                          <m.div
                            key={nav[visibleImage]?.image}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 0.2 }}
                          >
                            <Image image={nav[visibleImage]?.image} fill />
                          </m.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <div
                      className={classNames(
                        "relative h-full min-h-[3rem] w-full pt-2 transition duration-300",
                        {
                          "invisible opacity-0":
                            visibleImage === null &&
                            !nav[visibleImage]?.caption,
                        }
                      )}
                    >
                      {nav[visibleImage]?.caption && (
                        <Text
                          variant="caption"
                          unwidow
                          className={classNames(
                            "absolute transition duration-300",
                            {
                              "text-white": darkBackground,
                              "text-black": !darkBackground,
                            }
                          )}
                        >
                          {nav[visibleImage]?.caption}
                        </Text>
                      )}
                    </div>
                  </div>
                </div>

                {/* Header footer */}
                {footerNav && (
                  <footer
                    className={classNames(
                      "mt-4 w-full border-t-4 py-4 transition duration-300",
                      {
                        "border-white": darkBackground,
                        "border-black": !darkBackground,
                      }
                    )}
                  >
                    <ul className="flex flex-row flex-wrap items-start justify-between gap-2 gap-y-4 sm:flex-nowrap">
                      {footerNav?.map((link, i) => {
                        const { text, url } = link;

                        return (
                          <li
                            key={`header-footer-link--${i + 1}`}
                            className={classNames("transition duration-300", {
                              "text-white": darkBackground,
                              "text-black": !darkBackground,
                            })}
                          >
                            <ButtonAlt to={url}>
                              <Text variant="button">{text}</Text>
                            </ButtonAlt>
                          </li>
                        );
                      })}
                    </ul>
                  </footer>
                )}
              </Container>
            </m.div>
          )}
        </AnimatePresence>
      </nav>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
