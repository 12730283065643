import React, { useState } from "react";
import classNames from "classnames";
import { Text, Icon, Image, Container } from "@atoms";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const ImageModalSlide = ({
  heading,
  copy,
  backgroundImage,
  previousText,
  nextText,
  pageState,
  visibleState,
  shortTitle,
  length,
}) => {
  const lang = useLang();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentPage, setCurrentPage] = pageState || useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setVisible] = visibleState || useState(true);
  return (
    <div className="h-screen w-full overflow-y-auto">
      <div className="flex min-h-screen w-full flex-col justify-between ">
        {backgroundImage && (
          <Image className="z-0 opacity-30" image={backgroundImage} fill />
        )}
        <div className="relative z-10 flex h-full w-full flex-col justify-start p-20 md:justify-between">
          <Text variant="h2" className="mb-10 max-w-xl text-white">
            {heading}
          </Text>
          <div className="flex h-full w-full flex-col justify-between md:flex-row md:items-end md:justify-between">
            <div className="max-w-lg">
              <Text className="prose text-white" richText>
                {copy}
              </Text>
            </div>
            {backgroundImage?.attribution && (
              <div className="relative z-20 flex flex-col items-end text-white">
                <Text variant="caption">{t("Photo courtesy of", lang)}</Text>
                <Text className="whitespace-nowrap" variant="caption">
                  {backgroundImage.attribution}
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="relative z-10 w-full border-t-3 border-white">
          <Container variant="xl">
            <div className="flex flex-nowrap justify-between text-white">
              <button
                type="button"
                aria-label={`Go to the ${previousText} slide`}
                className={classNames("flex items-center gap-3 px-6 md:w-48", {
                  "pointer-events-none opacity-0": currentPage === 0,
                })}
                onClick={() =>
                  setCurrentPage(previousState =>
                    previousState > 0 ? previousState - 1 : previousState
                  )
                }
              >
                <Icon name="backwardsArrow" className="w-4 shrink-0" />
                <Text variant="button" className="hidden md:block">
                  {previousText}
                </Text>
              </button>
              <button
                type="button"
                className="p-4"
                aria-label={`Close the ${shortTitle} tab`}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" className="w-6" />
              </button>
              <button
                type="button"
                aria-label={`Go to the ${nextText} slide`}
                className={classNames(
                  "flex items-center gap-3 py-7 px-6 md:w-48",
                  {
                    "pointer-events-none opacity-0": currentPage === length - 1,
                  }
                )}
                onClick={() =>
                  setCurrentPage(previousState =>
                    previousState < length - 1
                      ? previousState + 1
                      : previousState
                  )
                }
              >
                <Text variant="button" className="hidden md:block">
                  {nextText}
                </Text>
                <Icon name="forwardArrow" className="w-4 shrink-0" />
              </button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

ImageModalSlide.defaultProps = {};

export default ImageModalSlide;
