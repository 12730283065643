const reducer = (state, action) => {
  switch (action.type) {
    case "openTranscript":
      return {
        ...state,
        transcript: true,
        heading: action.heading,
        subheading: action.subheading,
        copy: action.copy,
      };
    case "closeTranscript":
      return {
        ...state,
        transcript: false,
        heading: null,
        subheading: null,
        copy: null,
      };
    default:
      return state;
  }
};

const initialState = {
  transcript: false,
  heading: null,
  subheading: null,
  copy: null,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
