import React from "react";
import classNames from "classnames";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="text"
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames(
        "w-full max-w-full border-2 border-grey p-2 text-grey",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputText.defaultProps = {};

export default InputText;
