import React from "react";
import classNames from "classnames";
import { Container } from "@atoms";

const ArchContainer = ({ children, arch, color, removePadding }) => {
  const colors = {
    viridian: "bg-viridian",
    black: "bg-black",
  };
  return (
    <div className="relative z-10">
      <div
        className={classNames(
          "texture-pattern w-full pb-10 md:pb-16",
          colors[color],
          {
            "rounded-t-full": arch,
            "pt-[50vw] ": !removePadding,
          }
        )}
      >
        <Container>{children}</Container>
      </div>
    </div>
  );
};

ArchContainer.defaultProps = {
  color: "viridian",
};

export default ArchContainer;
