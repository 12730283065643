import React from "react";
import propTypes from "prop-types";
import { AppLink } from "@base";
import classNames from "classnames";
import Icon from "./Icon";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  icon,
  reverse,
}) => {
  const defaultClassName = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 uppercase font-bold group leading-tighter transition duration-200 rounded-tr-xl rounded-bl-xl`;

  const sizes = {
    fluid: "p-2 text-xs",
    xxs: "p-2 text-xs",
    xs: "px-5 py-2 text-xxs",
    md: "px-4 py-3 text-xs",
    lg: "px-10 py-5 text-base",
    sm: "py-2 px-4 text-base",
    default:
      "py-4 px-6 font-sans uppercase tracking-0 text-base font-bold leading-none",
  };

  const colors = {
    red: "bg-red hover:bg-red-dark text-white",
    viridian: "bg-viridian text-white",
    black: "bg-black text-white",
  };

  const classes = classNames(
    defaultClassName,
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
      "gap-3": icon,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        {/* back arrow */}
        {icon && reverse && (
          <span
            className={classNames(
              "icon flex-shink-0 mr-1 h-4 w-4 rotate-180 transform",
              {}
            )}
          >
            <Icon name={icon || "chevron"} />
          </span>
        )}
        <span className="relative z-10 flex text-center">{children}</span>
        {/* forward arrow */}
        {icon && !reverse && (
          <span className="icon flex-shink-0 ml-1 h-4 w-4">
            <Icon name={icon || "chevron"} />
          </span>
        )}
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={classNames(
            "icon flex-shink-0 mr-1 h-4 w-4 rotate-180 transform",
            {}
          )}
        >
          <Icon name={icon || "chevron"} />
        </span>
      )}
      <span className="relative z-10 flex text-center">{children}</span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span className="icon flex-shink-0 ml-1 h-4 w-4">
          <Icon name={icon || "chevron"} />
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "viridian",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Button;
