import React from "react";
import { Text, Container, Icon } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const IntentionModalContent = ({
  heading,
  description,
  learnMoreLink,
  supporterLink,
  disclaimer,
}) => {
  const [, dispatch] = useAppState();
  const lang = useLang();

  return (
    <section className="py-12 text-white sm:py-20 tall:md:py-32">
      <Container>
        <div className="mx-auto flex max-w-xl flex-col justify-between gap-10">
          <div>
            {heading && (
              <h2 className="mx-auto mb-8 max-w-md text-center font-magilio text-h5 sm:text-h4">
                {heading}
              </h2>
            )}
            {description && (
              <Text variant="lg" className="prose">
                {description}
              </Text>
            )}

            {(learnMoreLink || supporterLink) && (
              <div className="mt-8 flex flex-col items-center justify-between gap-8 sm:flex-row sm:items-start">
                {learnMoreLink?.url && (
                  <AppLink
                    to={learnMoreLink?.url}
                    className="underline decoration-3 underline-offset-8 transition duration-300 hover:decoration-white/20"
                  >
                    <span className="font-magilio text-[1rem]">
                      {learnMoreLink?.text}
                    </span>
                  </AppLink>
                )}

                {supporterLink?.url && (
                  <AppLink
                    to={supporterLink?.url}
                    className="underline decoration-3 underline-offset-8 transition duration-300 hover:decoration-white/20"
                  >
                    <span className="font-magilio text-[1rem]">
                      {supporterLink?.text}
                    </span>
                  </AppLink>
                )}
              </div>
            )}
          </div>

          <div className="relative pb-12 sm:pb-4">
            <div className="relative flex flex-col justify-center gap-4 sm:flex-row">
              {disclaimer && (
                <Text className="prose block text-xxs sm:absolute sm:left-0 sm:max-w-[11rem]">
                  {disclaimer}
                </Text>
              )}

              <button
                type="button"
                onClick={() => {
                  dispatch({ type: "closeModal" });
                }}
                aria-label="Close modal"
                className="group inset-0 mx-auto mt-6 flex justify-center self-center sm:mt-0"
              >
                <span className="flex flex-col items-center justify-center gap-2">
                  <span className="flex aspect-square w-10 items-center justify-center rounded-full bg-white transition duration-300 group-hover:-translate-y-2">
                    <Icon name="close" className="h-5 w-5 text-black" />
                  </span>
                  <span className="font-neutral text-xs font-bold uppercase">
                    {t("Close", lang)}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default IntentionModalContent;
