import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Container, Icon, Text } from "@atoms";
import LogoEn from "@svg/logo.svg";
import LogoFr from "@svg/logoFr.svg";
import t from "@utils/t";
import wait from "@utils/wait";
import { AppLink } from "@base";
import { m, AnimatePresence, useMotionValue } from "framer-motion";
import { toPng, toBlob } from "html-to-image";
import { saveAs } from "file-saver";
import IntentionBio from "./IntentionBio";
import IntentionShare from "./IntentionShare";

const IntentionCard = React.memo(
  ({
    uid,
    title,
    artwork,
    intention,
    pageLang,
    i,
    cardState,
    artist,
    color,
    backgroundColor,
    introCard,
    sectionState,
    shareCopy,
    background,
  }) => {
    // card state management
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selected, setSelected] = cardState || useState(-1);
    const [flipped, setFlipped] = useState(false);
    const [bioOpened, setBioOpened] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [artworkImage, setArtworkImage] = useState();
    const [backgroundImage, setBakgroundImage] = useState();
    const [enableControls, setEnableControls] = useState();
    // ios state
    const [iOS, setiOS] = useState(false);
    const [tapping, setTapping] = useState(false);

    // refs
    const sharableImage = useRef(null);
    const sharableImageInsta = useRef(null);
    // motion values
    const zIndex = useMotionValue(selected === i ? 40 : 0);

    const buildUrl = (url, params) =>
      `${url.replace(
        process.env.GATSBY_CDN_BASE_URL,
        process.env.GATSBY_IMGIX_BASE_URL
      )}?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`;

    const containerVariants = {
      closed: {
        width: "auto",
        height: "auto",
        transition: { delay: 1, duration: 3 },
      },
      open: { width: "100vw", height: "100vh", transition: { duration: 0 } },
    };

    const cardVariants = {
      hover: { scale: [null, 1.2], transition: { duration: 0.3 } },
      open: { scale: 2 },
      closed: { scale: [null, 1], transition: { duration: 0.3, delay: 0.2 } },
      tap: { scale: [null, 1.1], transition: { duration: 0.3 } },
    };

    const checkZIndex = latest => {
      if (latest.scale >= 1.15) {
        zIndex.set(40);
      } else {
        zIndex.set(0);
      }
    };

    const downloadImages = async () => {
      if (shareOpen && !downloading) {
        if (sharableImage.current) {
          const sharableId = sharableImage.current;
          const instaId = sharableImageInsta.current;

          try {
            setDownloading(true);
            await wait(400);
            await toPng(sharableId);
            await toPng(sharableId);
            await toPng(sharableId);
            await toPng(sharableId);
            await toPng(sharableId);
            const renderedImage = await toPng(sharableId);
            saveAs(renderedImage, "My EF intention.png");
            await wait(400);
            await toPng(instaId);
            await toPng(instaId);
            await toPng(instaId);
            await toPng(instaId);
            await toPng(instaId);
            const instagramImage = await toPng(instaId);
            saveAs(instagramImage, "My EF intention (Instagram).png");
            await wait(300);
            setDownloading(false);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      }
    };

    const shareImage = async () => {
      if (shareOpen) {
        const sharableId = sharableImageInsta.current;

        try {
          await wait(600);
          await toBlob(sharableId);
          await toBlob(sharableId);
          await toBlob(sharableId);
          await toBlob(sharableId);
          await toBlob(sharableId);
          await toBlob(sharableId);
          await toBlob(sharableId);
          const instagramImage = await toBlob(sharableId);
          const filesArray = [
            new File([instagramImage], "My EF intention (Instagram).png", {
              type: "image/png",
              lastModified: new Date().getTime(),
            }),
          ];
          const shareData = {
            title: "shareInstagram",
            files: filesArray,
          };

          if (navigator.canShare && navigator.canShare(shareData)) {
            await navigator.share(shareData);
          } else {
            await saveAs(instagramImage, "My EF intention (Instagram).png");
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    };

    useEffect(() => {
      if (!enableControls)
        setTimeout(() => {
          setSelected(-1);
        }, 1800);

      setiOS(
        ([
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
          // eslint-disable-next-line eqeqeq
          navigator.userAgent.search("Firefox") === -1 &&
          navigator.userAgent.search("FxiOS") === -1
      );
    }, []);

    useEffect(() => {
      const open = selected !== -1;
      if (open) {
        document.documentElement.classList.add("is-locked");
      } else {
        document.documentElement.classList.remove("is-locked");
        if (!enableControls) setEnableControls(true);
      }
    }, [selected]);

    useEffect(() => {
      const getArtworkImage = async imageUrl => {
        const response = await fetch(buildUrl(imageUrl, { w: 1000, q: 80 }));
        setArtworkImage(response.url);
      };
      const getBackgroundImage = async imageUrl => {
        const response = await fetch(buildUrl(imageUrl, { w: 1000, q: 80 }));
        setBakgroundImage(response.url);
      };

      getArtworkImage(artwork?.url);
      getBackgroundImage(background?.url || artwork?.url);
    }, []);

    return (
      <m.div style={{ zIndex }}>
        <div className="relative aspect-playing-card h-full w-[191px] shrink-0 transition duration-300 sm:w-full">
          <m.div
            variants={containerVariants}
            initial="closed"
            animate={selected === i ? "open" : "closed"}
            className={classNames({
              "fixed inset-0 flex flex-col items-center justify-center":
                selected === i,
              absolute: selected === -1,
              "pointer-events-none fixed inset-0 flex items-center justify-center opacity-0 delay-300":
                selected !== -1 && selected !== i,
            })}
          >
            <m.div
              className={classNames({
                "flex items-center gap-64 sm:gap-32": selected === i,
                "-translate-x-[30%] justify-end sm:translate-x-0 sm:justify-center":
                  bioOpened,
              })}
            >
              <m.div
                variants={cardVariants}
                aria-label={`View ${artist.title}'s artwork titled ${title}`}
                onClick={() => {
                  if (!iOS) {
                    if (selected === i) {
                      setFlipped(last => !last);
                    } else {
                      setSelected(i);
                    }
                  }
                }}
                onTap={() => {
                  if (iOS && tapping) {
                    if (selected === i) {
                      setFlipped(last => !last);
                    } else {
                      setSelected(i);
                    }
                  }
                }}
                onTapStart={() => setTapping(true)}
                onTapCancel={() => setTapping(false)}
                whileHover={selected !== i ? "hover" : "open"}
                whileTap={selected === -1 ? "tap" : false}
                animate={selected === i ? "open" : "closed"}
                className={classNames(
                  "intention-card aspect-playing-card w-[191px] shrink-0 bg-transparent",
                  {
                    "intention-card--flipped": flipped,
                    "cursor-pointer": selected !== i,
                    "relative -top-[15%]": selected !== -1 && selected !== i,
                  }
                )}
                layout
                onUpdate={checkZIndex}
              >
                <div
                  className={classNames("aspect-playing-card h-full w-full", {
                    "scale-[70%] average:sm:scale-90 tall:scale-75 tall:sm:scale-100":
                      selected === i,
                    "-translate-y-8 sm:translate-y-0":
                      selected === i && enableControls,
                  })}
                >
                  {/* front */}
                  <div className="intention-card__front absolute inset-0 h-full w-full overflow-hidden rounded-lg bg-black shadow-lg">
                    <div
                      className="absolute inset-0 h-full w-full"
                      style={{
                        backgroundImage: `url(${artworkImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                  </div>

                  {/* back */}
                  <div
                    className="intention-card__back pointer-events-none flex aspect-playing-card w-full flex-col items-center justify-between gap-4 overflow-hidden rounded-lg py-6 px-2 xxl:gap-3 xxl:py-4"
                    style={{
                      backgroundColor,
                    }}
                  >
                    <div className="relative aspect-auto aspect-portal w-12 overflow-hidden rounded-t-full xl:w-9 xxl:w-7">
                      <div
                        className="absolute inset-0 h-full w-full"
                        style={{
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      />
                    </div>

                    <m.h3
                      className={classNames(
                        "block w-full max-w-[10rem] px-1 text-center font-magilio",
                        {
                          "text-md xl:text-xxs": intention?.length >= 70,
                          "text-xl xl:text-sm xxl:text-xs":
                            intention?.length < 70,
                        }
                      )}
                      style={{
                        color,
                      }}
                    >
                      {intention}
                    </m.h3>

                    <div>
                      {(pageLang === "en" || pageLang === "es") && (
                        <span className="icon--fit-height h-5 overflow-visible xl:h-4">
                          <LogoEn />
                        </span>
                      )}
                      {pageLang === "fr" && (
                        <span className="icon--fit-height h-5 overflow-visible xl:h-4">
                          <LogoFr />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </m.div>

              {bioOpened && <IntentionBio {...artist} lang={pageLang} />}
            </m.div>

            {/* controls */}
            <AnimatePresence>
              {selected === i && enableControls && (
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.5 } }}
                  exit={{ opacity: 0, transition: { duration: 0 } }}
                  className="absolute bottom-28 z-10 mx-auto w-full max-w-xl sm:bottom-4 tall:sm:bottom-12 tall:xl:bottom-4"
                >
                  <Container variant="xxs">
                    <div className="flex w-full justify-between gap-6">
                      {/* flip the card */}
                      <button
                        type="button"
                        onClick={() => setFlipped(last => !last)}
                        aria-label={t("Flip the selected card", pageLang)}
                        className={classNames(
                          "group inset-0 mx-auto mt-6 flex justify-center transition duration-300 sm:mt-0",
                          {
                            "pointer-events-none translate-y-10 opacity-0":
                              bioOpened,
                          }
                        )}
                      >
                        <span className="flex flex-col items-center justify-center gap-2">
                          <span className="flex aspect-square w-8 items-center justify-center rounded-full bg-black transition duration-300 group-hover:-translate-y-2">
                            <Icon name="flip" className="h-4 w-4 text-white" />
                          </span>
                          <span className="font-neutral text-xxs font-bold uppercase">
                            {t("Flip", pageLang)}
                          </span>
                        </span>
                      </button>

                      {/* close extra stuff */}
                      <button
                        type="button"
                        onClick={() => {
                          if (bioOpened) {
                            setBioOpened(false);
                          } else {
                            setSelected(-1);
                            setFlipped(false);
                          }
                        }}
                        aria-label={t("Close the modal", pageLang)}
                        className={classNames(
                          "group mx-auto mt-6 flex justify-center transition duration-300 sm:mt-0",
                          {
                            "opacity-0": shareOpen,
                          }
                        )}
                      >
                        <span className="flex flex-col items-center justify-center gap-2">
                          <span className="flex aspect-square w-8 items-center justify-center rounded-full bg-black transition duration-300 group-hover:-translate-y-2">
                            <Icon name="close" className="h-4 w-4 text-white" />
                          </span>
                          <span className="font-neutral text-xxs font-bold uppercase">
                            {t("Close", pageLang)}
                          </span>
                        </span>
                      </button>

                      {/* share */}
                      <button
                        type="button"
                        onClick={() => setShareOpen(true)}
                        aria-label={t("Share the intention", pageLang)}
                        className={classNames(
                          "group inset-0 mx-auto mt-6 flex justify-center transition duration-300 sm:mt-0",
                          {
                            "pointer-events-none translate-y-10 opacity-0":
                              bioOpened,
                          }
                        )}
                      >
                        <span className="flex flex-col items-center justify-center gap-2">
                          <span className="flex aspect-square w-8 items-center justify-center rounded-full bg-black transition duration-300 group-hover:-translate-y-2">
                            <Icon name="share" className="h-4 w-4 text-white" />
                          </span>
                          <span className="font-neutral text-xxs font-bold uppercase">
                            {t("Share", pageLang)}
                          </span>
                        </span>
                      </button>
                    </div>

                    {/* learn more */}
                    <div className="12 mt-2 flex justify-center tall:mt-6 tall:xl:mt-2">
                      <button
                        type="button"
                        className={classNames(
                          "underline decoration-3 underline-offset-8 transition duration-300 hover:opacity-70",
                          {
                            "pointer-events-none opacity-0": bioOpened,
                          }
                        )}
                        onClick={() => {
                          if (flipped) setFlipped(false);
                          setBioOpened(true);
                        }}
                      >
                        <span className="text-xs font-bold">
                          {t("Learn About the Artist", pageLang)}
                        </span>
                      </button>
                    </div>
                  </Container>
                </m.div>
              )}
            </AnimatePresence>
          </m.div>
        </div>

        <AnimatePresence>
          {shareOpen && (
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
              className="fixed inset-0 z-[100] flex h-full w-full items-center justify-center bg-black/70 text-white"
            >
              <Container
                variant="xxs"
                className="flex items-center justify-center"
              >
                <div className="flex flex-col gap-7">
                  <Text variant="lg" className="prose max-w-[13rem]">
                    {shareCopy}
                  </Text>

                  <div className="border-t-3 border-white" />

                  <div>
                    <ul className="space-y-7">
                      <li>
                        <AppLink
                          className="flex items-center gap-3.5 transition duration-300 hover:opacity-70"
                          onClick={() => shareImage()}
                        >
                          <Icon name="instagram" className="w-6 shrink-0" />
                          <span className="text-xl font-bold xl:text-lg">
                            {t("Share Instagram Story", pageLang)}
                          </span>
                        </AppLink>
                      </li>

                      <li>
                        <AppLink
                          className={classNames(
                            "flex items-center gap-3.5 transition duration-300 hover:opacity-70",
                            {
                              "pointer-events-none animate-pulse": downloading,
                            }
                          )}
                          onClick={() =>
                            setTimeout(() => {
                              downloadImages();
                            }, 300)
                          }
                        >
                          <Icon
                            name="share"
                            className="ml-1 w-4 shrink-0 rotate-180"
                          />
                          <span className="text-xl font-bold xl:text-lg">
                            {t(
                              downloading
                                ? "Downloading"
                                : "Download and Share",
                              pageLang
                            )}
                          </span>
                        </AppLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Container>

              <button
                type="button"
                onClick={() => {
                  setShareOpen(false);
                }}
                aria-label={t("Close the share modal", pageLang)}
                className="group fixed bottom-[4rem] mx-auto mt-6 -ml-1 flex justify-center transition-colors duration-300 sm:mt-0 tall:md:bottom-[5.5rem]"
              >
                <span className="flex flex-col items-center justify-center gap-2">
                  <span className="flex aspect-square w-8 items-center justify-center rounded-full bg-white transition duration-300 group-hover:-translate-y-2">
                    <Icon name="close" className="h-4 w-4 text-black" />
                  </span>
                  <span className="font-neutral text-xxs font-bold uppercase">
                    Close
                  </span>
                </span>
              </button>
            </m.div>
          )}
        </AnimatePresence>

        {selected === i && (
          <>
            <IntentionShare
              shareRef={sharableImage}
              artist={artist}
              color={color}
              backgroundColor={backgroundColor}
              artwork={artworkImage}
              pageLang={pageLang}
              intention={intention}
              background={backgroundImage}
            />
            <IntentionShare
              shareRef={sharableImageInsta}
              artist={artist}
              color={color}
              backgroundColor={backgroundColor}
              artwork={artworkImage}
              pageLang={pageLang}
              intention={intention}
              background={backgroundImage}
              forInstagram
            />
          </>
        )}
      </m.div>
    );
  }
);

export default IntentionCard;
