import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query IntentionsGlobalQuery {
    craft {
      en: globalSet(handle: "intentions", site: "intentionCards") {
        ... on Craft_intentions_GlobalSet {
          # about
          buttonText: label0
          heading0
          descriptor0
          learnMoreLink: link0 {
            text
            url
          }
          supporterLink: link1 {
            text
            url
          }
          descriptor1
          # shareCopy
          shareCopy: descriptor2
          # daily intentions
          list0 {
            ... on Craft_list0_TableRow {
              text: col1
            }
          }
        }
      }
      fr: globalSet(handle: "intentions", site: "intentionCardsFr") {
        ... on Craft_intentions_GlobalSet {
          # about
          buttonText: label0
          heading0
          descriptor0
          learnMoreLink: link0 {
            text
            url
          }
          supporterLink: link1 {
            text
            url
          }
          descriptor1
          # shareCopy
          shareCopy: descriptor2
          # daily intentions
          list0 {
            ... on Craft_list0_TableRow {
              text: col1
            }
          }
        }
      }
      es: globalSet(handle: "intentions", site: "intentionCardsEs") {
        ... on Craft_intentions_GlobalSet {
          # about
          buttonText: label0
          heading0
          descriptor0
          learnMoreLink: link0 {
            text
            url
          }
          supporterLink: link1 {
            text
            url
          }
          descriptor1
          # shareCopy
          shareCopy: descriptor2
          # daily intentions
          list0 {
            ... on Craft_list0_TableRow {
              text: col1
            }
          }
        }
      }
    }
  }
`;

const dataResolver = globalSet => {
  const {
    buttonText,
    heading0,
    descriptor0,
    learnMoreLink,
    supporterLink,
    descriptor1,
    shareCopy,
    list0,
  } = globalSet;
  return {
    buttonText,
    about: {
      heading: heading0,
      description: descriptor0,
      disclaimer: descriptor1,
      learnMoreLink,
      supporterLink,
    },
    dailyIntentions: list0?.map(item => item.text),
    shareCopy,
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, fr, es } = craft;
  return {
    en: dataResolver(en),
    fr: dataResolver(fr),
    es: dataResolver(es),
  };
};

export default useData;
