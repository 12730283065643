import layout from "./reducers/layout";
import modal from "./reducers/modal";
import createAppState from "./lib/createAppState";
import audio from "./reducers/audio";
import transcript from "./reducers/transcript";
import sideScroll from "./reducers/sideScroll";
import translation from "./reducers/translation";

const reducer = {
  layout: layout.reducer,
  modal: modal.reducer,
  audio: audio.reducer,
  transcript: transcript.reducer,
  sideScroll: sideScroll.reducer,
  translation: translation.reducer,
};

const initialState = {
  layout: layout.initialState,
  modal: modal.initialState,
  audio: audio.initialState,
  transcript: transcript.initialState,
  sideScroll: sideScroll.reducer,
  translation: translation.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
