import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Text } from "@atoms";
import { useAppState } from "@state";
import { m } from "framer-motion";
import { navigate } from "gatsby";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const LanguageToggle = ({ pageLang, locales, intention }) => {
  const [, dispatch] = useAppState();

  const lang = useLang();

  const languageText =
    lang === "en"
      ? "Currently viewing english. Click to change to french"
      : "Actuellement affiché en français. Cliquez pour passer à l'anglais";

  useEffect(() => {
    console.log(pageLang, lang, locales);
    if (pageLang && lang && pageLang !== lang) {
      const prefix = lang !== "en" ? `/${lang}/` : "/";
      const uri = locales[lang] === "__home__" ? "" : locales[lang];
      navigate(prefix + uri);

      document.documentElement.classList.remove("is-locked");
      clearAllBodyScrollLocks();
    }
  }, [pageLang, lang]);

  return (
    <div
      className={classNames("uppercase transition duration-300", {
        "w-12": !intention,
        "w-24 sm:w-20": intention,
      })}
    >
      <div className="flex w-full justify-between">
        <button
          type="button"
          onClick={() => dispatch({ type: "setLang", lang: "en" })}
          aria-label={t("Click to view the site in English", lang)}
        >
          <Text
            variant="sm"
            className={classNames("transition duration-300", {
              "opacity-30": lang !== "en",
            })}
          >
            en
          </Text>
        </button>

        <button
          type="button"
          onClick={() => dispatch({ type: "setLang", lang: "fr" })}
          aria-label={t("Click to view the site in French", lang)}
        >
          <Text
            variant="sm"
            className={classNames("transition duration-300", {
              "opacity-30": lang !== "fr",
            })}
          >
            fr
          </Text>
        </button>

        {intention && (
          <button
            type="button"
            onClick={() => dispatch({ type: "setLang", lang: "es" })}
            aria-label={t("Click to view the site in Spanish", lang)}
          >
            <Text
              variant="sm"
              className={classNames("transition duration-300", {
                "opacity-30": lang !== "es",
              })}
            >
              es
            </Text>
          </button>
        )}
      </div>
      <div
        className={classNames("item-center relative flex w-full", {
          "justify-start": lang === "en",
          "justify-center": lang === "fr" && intention,
          "justify-end":
            (lang === "fr" && !intention) || (lang === "es" && intention),
        })}
      >
        <m.div layout className="h-1.5 w-1.5 rounded-full bg-current" />
        <div className="absolute inset-0 my-auto h-[4px] w-full bg-current" />
      </div>
    </div>
  );
};

LanguageToggle.defaultProps = {};

export default LanguageToggle;
