import React from "react";
import { Image, Text, Icon } from "@atoms";
import { AppLink } from "@base";
import t from "@utils/t";
import { m } from "framer-motion";

const IntentionBio = ({ title, headshot, bio, link, socials, lang }) => {
  return (
    <m.div
      className="flex w-full max-w-[17.5rem] shrink-0 -translate-y-10 flex-col gap-6 sm:max-w-xs"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.9 }}
    >
      {/* top bio header */}
      <div className="border-b-3 border-black pb-4 sm:pb-6">
        <m.div
          className="flex items-center justify-between gap-2"
          initial={{ y: -10 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.9 }}
        >
          <h3 className="block font-neutral text-sm font-bold uppercase leading-none tracking-0 md:text-h6">
            {title}
          </h3>
          <div className="relative aspect-square w-20 overflow-hidden rounded-full">
            <Image image={headshot} fill />
          </div>
        </m.div>
      </div>

      <m.div
        className="flex flex-col gap-4 sm:gap-6"
        initial={{ y: 10 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.9 }}
      >
        {/* bio */}
        <Text variant="lg" className="prose">
          {bio}
        </Text>

        <span className="flex flex-col gap-1 text-lg font-bold">
          {t("See more of their work at: ", lang)}
          <a
            className="underline transition duration-300 hover:opacity-70"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        </span>

        {/* socials */}
        <div className="flex flex-wrap gap-4">
          {socials?.map(social => {
            const { url, platform } = social;
            const handle = url?.replace(/\/$/, "")?.split("/")?.pop();

            return (
              <AppLink
                to={url}
                className="flex items-center gap-1 transition duration-300 hover:opacity-70"
                key={url}
              >
                <Icon
                  name={platform}
                  className="duration-400 h-3 w-4 transition"
                  fitHeight={platform === "facebook"}
                />
                <span className="text-lg font-bold">{handle}</span>
              </AppLink>
            );
          })}
        </div>
      </m.div>
    </m.div>
  );
};

export default IntentionBio;
