const reducer = (state, action) => {
  switch (action.type) {
    case "startAudio":
      return {
        ...state,
        play: true,
        url: action.url,
        global: action.global,
        title: action.title,
      };
    case "setMedia":
      return {
        ...state,
        play: false,
        url: action.url,
        global: action.global,
        title: action.title,
      };
    case "playAudio":
      return { ...state, play: true };
    case "pauseAudio":
      return { ...state, play: false };
    case "stopAudio":
      return { ...state, url: null, global: false, play: false };
    default:
      return state;
  }
};

const initialState = {
  modal: false,
  content: false,
  title: null,
  description: null,
  background: "bg-black",
  focusId: false,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
