import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";

const Player = loadable(() => import("./Audio/Player"));

const Audio = props => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true));
  if (loaded) return <Player {...props} />;
  return <div />;
};

Audio.defaultProps = {};

export default Audio;
