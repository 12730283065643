const resolveImage = image => {
  if (image && image[0]) {
    const {
      status,
      title,
      url,
      height,
      width,
      hasFocalPoint,
      focalPoint,
      attribution,
    } = image[0];
    return {
      status,
      title,
      url,
      height,
      width,
      focalPoint: hasFocalPoint ? focalPoint : false,
      attribution,
    };
  }
  return null;
};

export default resolveImage;
