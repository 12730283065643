// french translations
export default {
  "Learn more": "En savoir plus",
  "Photo courtesy of": "Photo avec l'aimable autorisation de",
  Explore: "En savoir plus",
  Download: "télécharger",
  "Previous Story": "Article précédent",
  "Next Story": "Article suivant",
  "Expand Story": "Agrandir l'article",
  Country: "Pays",
  "Email Address": "Courriel",
  Submit: "Soumettre",
  Africa: "L'afrique",
  "Asia & Pacific": "Asie & Pacifique",
  Caribbean: "Les Caraïbes",
  Europe: "L'europe",
  "Latin America": "L'amérique Latine",
  "Middle East": "Le Moyen-Orient",
  "Multi-Regional": "Multirégionale",
  Partners: "Partners",
  Region: "Région",
  "Organizations Funded": "Nombre d'organisations financées",
  "Amount Distributed in CAD": "Montant décaissé en dollars canadiens",
  "Previous Chapter": "Chapitre précédent",
  "Next Chapter": "Chapitre suivant",
  "Read the transcript": "Lire la transcription",
  // intentions
  Flip: "Retourner",
  Close: "Fermer",
  Share: "Partager",
  "Artwork by ": "Œuvres d'art ",
  "Learn About the Artist": "Découvrez l'artiste",
  "See more of their work at: ": "Ver más de su trabajo en: ",
  "Share Instagram Story": "Partagez une histoire Instagram",
  "Download and Share": "Télécharger et partager",
  Scroll: "Défiler",
  "Equality Fund homepage": "Page d'accueil du Fonds Égalité",
  About: "À propos",
};
