import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import classNames from "classnames";
import { useWindowSize } from "@utils";
import { Icon } from "@atoms";
// import tailwindConfig from "@theme";

const Carousel = ({
  children,
  indicators,
  maxVisible,
  className: _className,
  carouselState,
  hideArrows,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentSlide, setCurrentSlide] = carouselState || useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const { screens } = {
    xxs: { max: "350px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
    xxl: "2000px",
  };

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={classNames("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && !hideArrows && (
          <div className="translate-y-50 absolute -left-12 top-0 bottom-0 z-10 hidden transform items-center justify-center md:flex">
            <button
              className={classNames(
                "group flex items-center justify-center bg-red p-4 text-white transition duration-300",
                {
                  "opacity-20": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="arrow"
                className="relative right-0 h-4 w-4 rotate-180 transform transition duration-300 group-hover:-translate-x-1.5"
              />
            </button>
          </div>
        )}
        <m.div
          animate={carouselControls}
          className="flex gap-6"
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
          dragDirectionLock
        >
          {slides.map((slide, i) => (
            <div
              key={slide.uid || `${i}--${slideCount}`}
              // className={classNames("relative duration-300", {
              //   "opacity-20":
              //     i < currentSlide || i + 1 > currentSlide + visibleSlides,
              // })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </m.div>
        {/* next button */}
        {slideCount > 1 && !hideArrows && (
          <div className="absolute -right-12 top-0 bottom-0 z-10 hidden items-center justify-center md:flex">
            <button
              className={classNames(
                "group flex items-center justify-center bg-red p-4 text-white transition duration-300",
                {
                  "opacity-20": currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="arrow"
                className="relative right-0 h-4 w-4 transition duration-300 group-hover:translate-x-1.5"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul className="flex items-center justify-center">
          {slides.map((slide, i) => (
            <li
              key={
                slide.uid
                  ? `${slide.uid}--button`
                  : `${i}-${slideCount}--button`
              }
            >
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={classNames(
                  "mx-1 block h-2 w-2 rounded-full bg-red transition duration-300",
                  {
                    "opacity-50": currentSlide !== i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-14 flex items-center justify-between border-t-2 border-white py-6 font-bold uppercase text-white md:mt-24 lg:mt-32">
        <button
          type="button"
          className={
            (classNames("flex items-center gap-2"),
            { "pointer-events-none opacity-0": currentSlide === 0 })
          }
          onClick={() =>
            setCurrentSlide(prevState => {
              if (prevState > 0) {
                return prevState - 1;
              }
              return prevState;
            })
          }
        >
          <Icon name="arrow" className="h-4 w-4 rotate-180" /> Previous Story
        </button>
        {/* <button><Icon name="close" className="mx-auto h-8 w-8" /> */}
        <button
          type="button"
          className={
            (classNames("flex items-center gap-2"),
            {
              "pointer-events-none opacity-0":
                currentSlide >= slideCount - visibleSlides,
            })
          }
          onClick={() =>
            setCurrentSlide(prevState => {
              if (prevState < slideCount - visibleSlides) {
                return prevState + 1;
              }
              return prevState;
            })
          }
        >
          Next Story <Icon name="arrow" className="h-4 w-4" />
        </button>
      </div>
    </>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
};

export default Carousel;
