import { useState, useEffect } from "react";

const hasWindow = typeof window !== "undefined";
const hasDarkTheme =
  hasWindow && window.matchMedia(`(prefers-color-scheme: dark)`);
const isDark = hasDarkTheme.matches;

const getTheme = () => {
  if (isDark) {
    return "dark";
  }
  return "light";
};

const useBrowserTheme = () => {
  const [theme, setTheme] = useState(getTheme());

  const handleTheme = event => {
    if (event.matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  useEffect(() => {
    hasDarkTheme.addEventListener("change", handleTheme);
    return () => hasDarkTheme.removeEventListener("change", handleTheme);
  }, []);

  return theme;
};

export default useBrowserTheme;
