const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        modal: true,
        content: action.content,
        title: action.title,
        description: action.description,
        background: action.background || initialState.background,
        focusId: action.focusId,
      };
    case "closeModal":
      return {
        ...state,
        modal: false,
      };
    case "clearModalContent":
      if (state.content === false) break;
      return {
        ...state,
        modal: false,
        content: false,
        background: initialState.background,
        focusId: false,
      };
    default:
      return state;
  }
  return state;
};

const initialState = {
  modal: false,
  content: false,
  title: null,
  description: null,
  background: "bg-black",
  focusId: false,
};

const layout = {
  reducer,
  initialState,
};

export default layout;
