import React from "react";
import classNames from "classnames";
import { Text } from "@atoms";

const DeepDiveHeadingSlide = ({ color, heading, caption, bleedHeading }) => {
  const colors = {
    viridian: "bg-viridian",
    coral: "bg-coral",
  };
  const textStyles = {
    viridian: "text-white",
    coral: "text-black",
  };
  return (
    <div
      className={classNames(
        "texture-pattern relative h-full h-[calc(100vh-4.2rem)] w-full shrink-0 self-start py-10 md:max-w-xl md:py-16 lg:py-24",
        colors[color],
        textStyles[color]
      )}
    >
      <div className="flex h-full flex-col justify-between px-4 lg:px-12 xl:px-8">
        <Text
          variant="h2"
          className={classNames({ "relative -left-1/2": bleedHeading })}
        >
          {heading}
        </Text>
        {caption && (
          <Text variant="caption" className="pb-16">
            {caption}
          </Text>
        )}
      </div>
    </div>
  );
};

DeepDiveHeadingSlide.defaultProps = {};

export default DeepDiveHeadingSlide;
